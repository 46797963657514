import React from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import paypalStore from "store/paypal";
import mainStore from "store/store";
import { sendMessageToUser } from "pages/websockets";

function Paypal({ offer, amount, upgradedToKing }) {
  const navigate = useNavigate();

  const { createPaypalOrder, getPaypalOrderDetails } = paypalStore();
  const { setPaymentIntent, startCountdown, startTimer } = mainStore();

  const createOrder = async () => {
    const res = await createPaypalOrder({ amount: amount });
    if (res?.id) {
      return res.id;
    }
  };

  const onApprove = async (info) => {
    const data = await getPaypalOrderDetails(info.orderID);
    console.log("onApprove ~ data:", data);

    const shipping = data.purchase_units[0].shipping.address;
    const amountDetails = data.purchase_units[0].amount;

    const customer = {
      firstName: data.payer?.name?.given_name,
      lastName: data.payer?.name?.surname,
      email: data.payer.email_address,
      line1: shipping.address_line_1,
      line2: "",
      city: shipping.admin_area_2,
      state: shipping.admin_area_1,
      country: shipping.country_code,
      postal_code: shipping.postal_code,
    };

    const intentData = {
      paymentId: info.orderID,
      paymentMethodId: info.paymentID,
      gateway: info.paymentSource,
      amount: amount,
      details: {
        customer: customer,
        upgradedToKing: upgradedToKing,
        amount: amount,
        currency: amountDetails.currency_code,
        status: data.status,
        offer: offer,
        items: [
          {
            title: offer.title,
            price: amount,
            quantity: 1,
            sku: offer.sku,
            product_id: offer.productId,
          },
        ],
      },
    };
    setPaymentIntent(intentData);
    sendMessageToUser(intentData);
    startCountdown();
    startTimer();
    if (intentData.details.upgradedToKing) {
      navigate("/up-sell-1b");
    } else {
      navigate("/up-sell-1a");
    }
  };

  return (
    <>
      <PayPalScriptProvider
        key={amount}
        options={{
          "client-id":
            "AaXsYfCq4nyz5gnUFCFwuDzcUeolyGOhRA8LgPJkcGto2Q6LfbqbdLSeAc-Qduex06tTlUX373xWdnCx",
        }}
      >
        <PayPalButtons
          fundingSource="paypal"
          createOrder={() => {
            return createOrder();
          }}
          onApprove={(data) => {
            return onApprove(data);
          }}
          onError={() => {}}
        />
        {/* <div id="paypal-button-container">
          <PayPalButtons
            fundingSource="card"
            createOrder={() => {
              return createOrder();
            }}
            onApprove={(data) => {
              return onApprove(data);
            }}
          />
        </div> */}
      </PayPalScriptProvider>
    </>
  );
}

export default Paypal;

import stripeStore from "store/stripe";
import paypalStore from "store/paypal";
import NMIStore from "store/nmi";
import mainStore from "store/store";
import FinalOrder from "pages/finalOrder/finalOrder";
import { useNavigate } from "react-router-dom";
import { sendMessageToUser } from "pages/websockets";

function Mixin() {
  const navigate = useNavigate();
  const { setPaymentIntent, setAll } = mainStore();
  const { confirmPayment } = stripeStore();
  const { authorizeAndCapturePayment } = NMIStore();
  const { capturePaypalOrder } = paypalStore();
  const { saveFinalOrder } = FinalOrder();

  const placeOrder = async (paymentIntent) => {
    let confirm = null;
    if (paymentIntent.gateway === "stripe") {
      confirm = await confirmPayment({
        paymentIntentId: paymentIntent.paymentId,
        paymentMethod: paymentIntent.paymentMethodId,
      });
    } else if (
      paymentIntent.gateway === "paypal" ||
      paymentIntent.gateway === "card"
    ) {
      confirm = await capturePaypalOrder({
        orderID: paymentIntent.paymentId,
      });
      const transactionDetails = confirm.purchase_units[0].payments.captures[0];
      paymentIntent.paymentId = transactionDetails.id;
    } else if (paymentIntent.gateway === "nmi") {
      if (!paymentIntent.firstOrder) {
        const offerAmount = paymentIntent.details?.items[0]?.price;
        if (offerAmount) {
          const updatedAmount =
            Math.round((paymentIntent.amount - offerAmount) * 100) / 100;
          confirm = await authorizeAndCapturePayment({
            ...paymentIntent.nmiDetails,
            amount: updatedAmount,
          });
          paymentIntent.paymentId = confirm.transactionid;
        }
      } else {
        confirm = {
          response_code: 100,
        };
      }
    }

    if (confirm) {
      if (paymentIntent.gateway !== "nmi" || confirm.response_code == 100) {
        setPaymentIntent(paymentIntent);
        await saveFinalOrder(paymentIntent);
        sendMessageToUser({ orderComplete: true });
        navigate("/thank-you");
      } else if (paymentIntent.gateway === "nmi") {
        alert(confirm.responsetext);
      }
    }
  };

  return {
    placeOrder,
  };
}

export default Mixin;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import mainStore from "store/store";
import stripeStore from "store/stripe";
import paypalStore from "store/paypal";
import { sendMessageToUser } from "pages/websockets";
import Mixin from "pages/mixin/global";
import { trackUpsellAccepted, trackPageViewEvent } from "mixpanel/events";
import ClipLoader from "react-spinners/ClipLoader";

function UpSell2A() {
  const navigate = useNavigate();
  const { placeOrder } = Mixin();
  const {
    allProductOffers,
    paymentIntent,
    setPaymentIntent,
    formattedTime,
    allPages,
    setAllPages,
    orderPlaced,
  } = mainStore();
  const { updatePaymentIntent } = stripeStore();
  const { updatePaypalOrder } = paypalStore();

  const [loading, setLoading] = useState(false);
  const [loadingGLobal, setLoadingGlobal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState({});

  useEffect(() => {
    initializeUpsell2A();
  }, []);

  useEffect(() => {
    if (paymentIntent) {
      const pillowcases = allProductOffers.products.find((cases) => {
        return cases.sku === "NZ3001";
      });

      if (pillowcases) {
        setSelectedDeal(pillowcases);
      }
    }
  }, [allProductOffers, paymentIntent]);

  const initializeUpsell2A = async () => {
    if (!paymentIntent) {
      navigate("/");
    }

    console.log("initializeUpsell1A ~ allPages:", allPages);
    if (allPages.includes("up-sell-2a")) {
      setLoadingGlobal(true);
      console.log("initializeUpsell2A ~ orderPlaced:", orderPlaced);
      if (!orderPlaced) {
        await placeOrder(paymentIntent);
      } else {
        navigate("/thank-you");
      }
      setLoadingGlobal(false);
    }

    const pages = [...allPages];
    pages.push("up-sell-2a");
    setAllPages(pages);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    trackPageViewEvent({ page_type: "Upsell 2A" });
  };

  const onAddPillow = async () => {
    try {
      let res = null;
      let amount = 0;
      setLoading(true);

      if (paymentIntent.gateway === "stripe") {
        amount = paymentIntent.amount + selectedDeal.afterDiscountValue * 100;
        res = await updatePaymentIntent({
          paymentIntentId: paymentIntent.paymentId,
          amount: amount,
        });
      } else if (
        paymentIntent.gateway === "paypal" ||
        paymentIntent.gateway === "card"
      ) {
        amount =
          Math.round(
            (paymentIntent.amount + selectedDeal.afterDiscountValue) * 100
          ) / 100;
        res = await updatePaypalOrder({
          orderID: paymentIntent.paymentId,
          amount: amount,
        });
      } else if (paymentIntent.gateway === "nmi") {
        amount =
          Math.round(
            (paymentIntent.amount + selectedDeal.afterDiscountValue) * 100
          ) / 100;
        res = {};
      }

      if (res) {
        const newPaymentIntent = { ...paymentIntent };
        newPaymentIntent.amount = amount;
        newPaymentIntent.firstOrder = false;
        newPaymentIntent.details.items.push({
          title: selectedDeal.title,
          price: selectedDeal.afterDiscountValue,
          quantity: 1,
          sku: selectedDeal.sku,
          product_id: selectedDeal.productId,
        });
        trackUpsellAccepted(selectedDeal.sku);
        await placeOrder(newPaymentIntent);
        // setPaymentIntent(newPaymentIntent);
        // sendMessageToUser(newPaymentIntent);
        // navigate("/up-sell-4a");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Cannot add at the moment.");
    }
  };

  const navigateToNextUpsell = () => {
    setLoading(false);
    navigate("/up-sell-3a");
  };

  return (
    <>
      {loadingGLobal && (
        <div className="global-loader">
          <ClipLoader color="#fff" size={100} />
        </div>
      )}
      <div className="progressbar-section">
        <div>
          <img
            src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f222c4dc2ef4647b6c690_progress%20bar.svg"
            loading="lazy"
            alt=""
            className="image-18"
          />
        </div>
      </div>
      <div className="logo-section">
        <div className="container-3 container-upsell w-container">
          <div className="upsell-header">
            <div className="logo-block upsell-logo-block">
              <img
                src="../images/logo_v02.png"
                loading="lazy"
                width="113"
                alt=""
              />
            </div>
            <div className="progress-container">
              <div className="progress-step">
                <a href="#" className="progress-link w-inline-block">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2d756903139a8964573_one.png"
                    loading="lazy"
                    alt=""
                    className="image-21 link-img"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f27534ea18b1c3eedbe8a_st1.svg"
                    loading="lazy"
                    alt=""
                    className="pr-r r-pr-r"
                  />
                </a>
                <p className="progress-p">Checkout</p>
              </div>
              <div className="progress-step">
                <a href="#" className="progress-link w-inline-block">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2ebbce1fa36907e580e_two.png"
                    loading="lazy"
                    alt=""
                    className="image-21 link-img"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f277596949e5bacc01176_st2.svg"
                    loading="lazy"
                    width="414"
                    alt=""
                    className="pr-r c-pr-r"
                  />
                </a>
                <p className="progress-p">Special Deal</p>
              </div>
              <div className="progress-step ls">
                <div className="progress-link">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2f9037c341fc9b203c4_three.png"
                    loading="lazy"
                    alt=""
                    className="image-21 link-img"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f279000ea000b17acdf08_st3.svg"
                    loading="lazy"
                    alt=""
                    className="pr-r l-pr-r"
                  />
                </div>
                <p className="progress-p">Summary</p>
              </div>
              <img
                src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa9a0a2c9d72c22d4eb3c_rl.svg"
                loading="lazy"
                alt=""
                className="image-22"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="main-section main-section-upsell">
        <div className="container-3 container-upsell main-container-upsell w-container">
          <div className="upsell-main-top-block">
            <h5 className="sale-title">
              WAIT! YOU QUALIFIED FOR A
              <span className="text-span-87">BOGO DEAL!</span>
            </h5>
          </div>
          <div className="timer-div">
            <div className="note-text">
              This amazing deal expires in just{" "}
              <span id="timer" className="checkout-timer">
                {formattedTime}
              </span>{" "}
              minutes, so don&#x27;t let it slip away. 
            </div>
          </div>
          <div className="center-div">
            <p className="sale-p">
              Add some extra pillows to your order with an exclusive, limited
              time BOGO deal that we reserved just for you! By taking advantage
              of this one-time offer, you get
              <strong>two extra pillows for the price of one!</strong>
            </p>
          </div>
          <div className="columns upsell-cl w-row">
            <div className="column-2 upsell-slider-coloumn w-col w-col-6">
              <div className="upsell-slider-wrapper">
                <div className="main-dots upsell-dots">
                  <div className="slide-dot">
                    <img
                      src="../images/bb2.png"
                      loading="lazy"
                      sizes="(max-width: 479px) 96vw, (max-width: 767px) 100vw, (max-width: 991px) 45vw, 445px"
                      srcset="
                      ../images/bb2-p-500.png 500w,
                      ../images/bb2.png       754w
                    "
                      alt=""
                      className="slide-dot-iimg"
                    />
                  </div>
                  <div className="slide-dot"></div>
                  <div className="slide-dot"></div>
                  <div className="slide-dot"></div>
                  <div className="slide-dot"></div>
                </div>
                <img
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f176839d9035414ca219f_prev.svg"
                  loading="lazy"
                  alt=""
                  className="prev-arr slick-arrow upsell-arr"
                />
                <img
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f17805bff702023229745_next.svg"
                  loading="lazy"
                  alt=""
                  className="next-arr slick-arrow upsell-arr"
                />
                <img
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/6270e07371b4bf03848279bc_best.png"
                  loading="lazy"
                  alt=""
                  className="best-slider-icon"
                />
              </div>
            </div>
            <div className="column-3 w-col w-col-6">
              <div
                id="redeem"
                className="main-text-block upsell-main-text-block"
              >
                <h1 className="main-title _2ndname">Nuzzle Pillow</h1>
                <div className="stars-block">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/623750a74497753d5f3105f3_rating%20stars.svg"
                    loading="lazy"
                    alt=""
                  />
                  <p className="stars-text upsell-stars-text">410 Reviews</p>
                </div>
                <div className="gift-row upsell-gift-row">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e96079fa1145743416b70_free.svg"
                    loading="lazy"
                    alt=""
                    className="image-13"
                  />
                  <div className="gift-text-block">
                    <p className="paragraph-15 upsell-p15">
                      <strong className="bold-text-3">
                        This offer is available only with today’s purchase!
                      </strong>
                    </p>
                  </div>
                </div>
                <p className="paragraph-11 upsell-p11">
                  Our customers always come back for more once they feel the
                  life changing differences in their sleep and health. Transform
                  all the rooms in your house into the ultimate cloud nine, or
                  even keep an extra pillow on hand for gifting to friends and
                  family and take advantage of this huge discount opportunity.
                </p>
                <div className="pp_div">
                  <div className="div_ss">
                    <div className="div-block-450">
                      <div className="ss-text">
                        ADD 1 PILLOW TO YOU ORDER TODAY
                        <br />
                        AND GET A 2ND FREE
                      </div>
                    </div>
                    <div>
                      <div className="text-block-121">Same as</div>
                      <h1 className="heading-24"></h1>
                      <div className="text-block-112">
                        Orig ${selectedDeal.retailValue}
                      </div>
                      <div>
                        Discounted Price: ${selectedDeal.afterDiscountValue}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-upsell-type="add-variant"
                  className="accept_upsell_button checkout-accept-upsell w-inline-block"
                >
                  <button
                    className={`button-gold checkout-accept-upsell2 ${
                      loading ? "order-processing" : ""
                    }`}
                    style={{
                      backgroundColor: loading ? "#294a59" : "#29af5c",
                    }}
                    disabled={loading}
                    onClick={() => onAddPillow()}
                  >
                    <div className="button-txt-icon-2">
                      <div className="add-to-order-2">
                        {loading ? (
                          <span className="pay-now">
                            <i> Processing Order</i>{" "}
                            <PulseLoader color="#ffffff" size={8} />
                          </span>
                        ) : (
                          <strong>YES! ADD TO MY ORDER!</strong>
                        )}
                      </div>
                    </div>
                  </button>
                </div>
                <div
                  data-current="Price Tier 1"
                  data-easing="ease"
                  data-duration-in="0"
                  data-duration-out="0"
                  className="tabs-3 _2ndone w-tabs"
                >
                  <div className="tabs-menu-3 w-tab-menu">
                    <a
                      data-w-tab="Price Tier 1"
                      data-qty="3"
                      data-variant-id="40576544309409"
                      className="upsell-select-variant w-inline-block w-tab-link w--current"
                    >
                      <img
                        loading="lazy"
                        alt=""
                        src="../images/pp2_v2.png"
                        className="image-85"
                      />
                      <div className="price-type-6">
                        <span className="text-span-75">
                          2x <br />
                        </span>
                        Nuzzle Pillows
                      </div>
                    </a>
                    <a
                      data-w-tab="Price Tier 2"
                      data-qty="3"
                      data-variant-id="40576544309409"
                      className="upsell-select-variant w-inline-block w-tab-link"
                    >
                      <img
                        alt=""
                        loading="lazy"
                        src="../images/pp1.png"
                        className="image-85"
                      />
                      <div className="price-type-6">
                        <span className="text-span-76">1x </span>
                        <br />
                        Nuzzle Pillow
                      </div>
                    </a>
                  </div>
                  <div className="tabs-content-4 w-tab-content">
                    <div
                      data-w-tab="Price Tier 1"
                      className="tab-content-2 w-tab-pane w--tab-active"
                    >
                      <div className="tab-content-inner">
                        <div
                          data-current="Price Tier 1"
                          data-easing="ease"
                          data-duration-in="0"
                          data-duration-out="0"
                          className="tabs-3 w-tabs"
                        >
                          <div className="tabs-content-4 w-tab-content">
                            <div
                              data-w-tab="Price Tier 1"
                              className="tab-content-2 w-tab-pane w--tab-active"
                            >
                              <div className="tab-content-inner">
                                <a
                                  data-upsell-param1="40938233299105"
                                  data-upsell-type="add-variant"
                                  data-next-url="https://try.javycoffee.com/receipt/"
                                  href="#"
                                  className="accept_upsell_button checkout-accept-upsell w-inline-block"
                                >
                                  <div className="button-gold">
                                    <div className="button-txt-icon-2">
                                      <div className="add-to-order">
                                        Add To My Order with 1 Click Buy!
                                      </div>
                                    </div>
                                    <div className="button-span-2 ct">
                                      TRY IT RISK FREE! 30-DAY MONEY BACK
                                      GUARANTEE
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div
                              data-w-tab="Price Tier 2"
                              className="tab-content-2 w-tab-pane"
                            >
                              <div className="tab-content-inner">
                                <a
                                  data-upsell-param1="40938233200801"
                                  data-upsell-type="add-variant"
                                  data-next-url="https://try.javycoffee.com/receipt/"
                                  href="#"
                                  className="accept_upsell_button checkout-accept-upsell w-inline-block"
                                >
                                  <div className="button-gold">
                                    <div className="button-txt-icon-2">
                                      <div className="add-to-order">
                                        Yes, Add To My Order!
                                      </div>
                                    </div>
                                    <div className="button-span-2 ct">
                                      TRY IT RISK FREE! 30-DAY MONEY BACK
                                      GUARANTEE
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div
                              data-w-tab="Price Tier 3"
                              className="tab-content-2 w-tab-pane"
                            >
                              <div className="tab-content-inner">
                                <a
                                  data-upsell-param1="40938233233569"
                                  data-upsell-type="add-variant"
                                  data-next-url="https://try.javycoffee.com/receipt/"
                                  href="#"
                                  className="accept_upsell_button checkout-accept-upsell w-inline-block"
                                >
                                  <div className="button-gold">
                                    <div className="button-txt-icon-2">
                                      <div className="add-to-order">
                                        Yes, Add To My Order!
                                      </div>
                                    </div>
                                    <div className="button-span-2 ct">
                                      TRY IT RISK FREE! 30-DAY MONEY BACK
                                      GUARANTEE
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div
                              data-w-tab="Price Tier 4"
                              className="tab-content-2 w-tab-pane"
                            >
                              <div className="tab-content-inner">
                                <a
                                  data-upsell-param1="40938233266337"
                                  data-upsell-type="add-variant"
                                  data-next-url="https://try.javycoffee.com/receipt/"
                                  href="#"
                                  className="accept_upsell_button checkout-accept-upsell w-inline-block"
                                >
                                  <div className="button-gold">
                                    <div className="button-txt-icon-2">
                                      <div className="add-to-order">
                                        Yes, Add To My Order!
                                      </div>
                                    </div>
                                    <div className="button-span-2 ct">
                                      TRY IT RISK FREE! 30-DAY MONEY BACK
                                      GUARANTEE
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-w-tab="Price Tier 2"
                      className="tab-content-2 w-tab-pane"
                    >
                      <div className="tab-content-inner">
                        <div
                          data-current="Price Tier 1"
                          data-easing="ease"
                          data-duration-in="0"
                          data-duration-out="0"
                          className="tabs-3 w-tabs"
                        >
                          <div className="tabs-content-4 w-tab-content">
                            <div
                              data-w-tab="Price Tier 1"
                              className="tab-content-2 w-tab-pane w--tab-active"
                            >
                              <div className="tab-content-inner">
                                <a
                                  data-upsell-param1="40938233331873"
                                  data-upsell-type="add-variant"
                                  data-next-url="https://try.javycoffee.com/receipt/"
                                  href="#"
                                  className="accept_upsell_button checkout-accept-upsell w-inline-block"
                                >
                                  <div className="button-gold">
                                    <div className="button-txt-icon-2">
                                      <div className="add-to-order">
                                        Yes, Add To My Order!
                                      </div>
                                    </div>
                                    <div className="button-span-2 ct">
                                      TRY IT RISK FREE! 30-DAY MONEY BACK
                                      GUARANTEE
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div
                              data-w-tab="Price Tier 2"
                              className="tab-content-2 w-tab-pane"
                            >
                              <div className="tab-content-inner">
                                <a
                                  data-upsell-param1="40938233430177"
                                  data-upsell-type="add-variant"
                                  data-next-url="https://try.javycoffee.com/receipt/"
                                  href="#"
                                  className="accept_upsell_button checkout-accept-upsell w-inline-block"
                                >
                                  <div className="button-gold">
                                    <div className="button-txt-icon-2">
                                      <div className="add-to-order">
                                        Yes, Add To My Order!
                                      </div>
                                    </div>
                                    <div className="button-span-2 ct">
                                      TRY IT RISK FREE! 30-DAY MONEY BACK
                                      GUARANTEE
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div
                              data-w-tab="Price Tier 3"
                              className="tab-content-2 w-tab-pane"
                            >
                              <div className="tab-content-inner">
                                <a
                                  data-upsell-param1="40938233364641"
                                  data-upsell-type="add-variant"
                                  data-next-url="https://try.javycoffee.com/receipt/"
                                  href="#"
                                  className="accept_upsell_button checkout-accept-upsell w-inline-block"
                                >
                                  <div className="button-gold">
                                    <div className="button-txt-icon-2">
                                      <div className="add-to-order">
                                        Yes, Add To My Order!
                                      </div>
                                    </div>
                                    <div className="button-span-2 ct">
                                      TRY IT RISK FREE! 30-DAY MONEY BACK
                                      GUARANTEE
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div
                              data-w-tab="Price Tier 4"
                              className="tab-content-2 w-tab-pane"
                            >
                              <div className="tab-content-inner">
                                <a
                                  data-upsell-param1="40938233397409"
                                  data-upsell-type="add-variant"
                                  data-next-url="https://try.javycoffee.com/receipt/"
                                  href="#"
                                  className="accept_upsell_button checkout-accept-upsell w-inline-block"
                                >
                                  <div className="button-gold">
                                    <div className="button-txt-icon-2">
                                      <div className="add-to-order">
                                        Yes, Add To My Order!
                                      </div>
                                    </div>
                                    <div className="button-span-2 ct">
                                      TRY IT RISK FREE! 30-DAY MONEY BACK
                                      GUARANTEE
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-information upsell-form-inf">
                  <div className="g-text">
                    TRY IT RISK FREE! 90-DAY MONEY BACK GUARANTEE
                  </div>
                </div>
                <a
                  className="no-offer-block w-inline-block cursor-pointer"
                  onClick={() => {
                    navigateToNextUpsell();
                  }}
                >
                  <p className="no-offer-text">
                    No, thank you! I don&#x27;t want this one time offer.
                  </p>
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625ef1f759479c8b18be44f8_Path.svg"
                    loading="lazy"
                    alt=""
                    className="image-17"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-6">
        <div className="inner-upsell">
          <div className="text-block-24">
            <a
              href="../legal/privacy-policy.html"
              target="_blank"
              className="link-5"
            >
              Privacy Policy
            </a>
            | 
            <a
              href="../legal/terms-conditions.html"
              target="_blank"
              className="link-5"
            >
              Terms &amp; Conditions
            </a>
            |
            <a
              href="../legal/return-policy.html"
              target="_blank"
              className="link-8"
            >
              Refund Policy
            </a>
            |
            <a
              href="../legal/contact-us.html"
              target="_blank"
              className="link-9"
            >
              Contact
            </a>
            | 
            <a href="../about-us.html" target="_blank" className="link-10">
              About
            </a>
            <br />© 2023 Nuzzle. All Rights Reserved.
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default UpSell2A;

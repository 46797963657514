import React, { useEffect } from "react";
import Header from "./header";
import Banner from "./banner";
import SliderMain from "./slider";
import Info from "./info";
import RatingReviews from "./rating-reviews";
import FaqMoney from "./faq-money";
import Extras from "./extras";
import Footer from "./footer";
import { trackPageViewEvent } from "mixpanel/events";

function Home() {
  useEffect(() => {
    trackPageViewEvent({ page_type: "Home" });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <div class="wrapper mobile-lp3">
      <Header />
      <SliderMain />
      <Info />
      <RatingReviews />
      <Extras />
      <Footer />
    </div>
  );
}

export default Home;

export function validateNMIForm(values, isBillingAddress) {
  const errors = {};
  if (!values.phone) {
    errors.phone = "Required";
  }
  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.country) {
    errors.country = "Required";
  }
  if (!values.line1) {
    errors.line1 = "Required";
  }
  if (!values.city) {
    errors.city = "Required";
  }
  if (!values.state) {
    errors.state = "Required";
  }
  if (!values.postal_code) {
    errors.postal_code = "Required";
  }

  if (!isBillingAddress) {
    errors.billingAddress = {};

    if (!values.billingAddress.first_name) {
      errors.billingAddress.first_name = "Required";
    }
    if (!values.billingAddress.last_name) {
      errors.billingAddress.last_name = "Required";
    }

    if (!values.billingAddress.country) {
      errors.billingAddress.country = "Required";
    }
    if (!values.billingAddress.address1) {
      errors.billingAddress.address1 = "Required";
    }
    if (!values.billingAddress.city) {
      errors.billingAddress.city = "Required";
    }
    if (!values.billingAddress.state) {
      errors.billingAddress.state = "Required";
    }
    if (!values.billingAddress.zip) {
      errors.billingAddress.zip = "Required";
    }
  }

  return errors;
}

import { create } from "zustand";
import axiosInstance from "axios-interceptor/axios";

const paypalStore = create(() => ({
  createPaypalOrder: async (data) => {
    try {
      const res = await axiosInstance.post("/paypal/createOrder", data);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  getPaypalOrderDetails: async (orderID) => {
    try {
      const res = await axiosInstance.get(`/paypal/getOrderDetails/${orderID}`);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  updatePaypalOrder: async (data) => {
    try {
      const res = await axiosInstance.patch("/paypal/updateOrder", data);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  capturePaypalOrder: async (data) => {
    try {
      const res = await axiosInstance.post("/paypal/captureOrder", data);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  refundPaypalPayment: async (data) => {
    try {
      const res = await axiosInstance.post("/paypal/refundPayment", data);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },
}));

export default paypalStore;

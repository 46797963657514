import { create } from "zustand";
import axiosInstance from "axios-interceptor/axios";

const NMIStore = create(() => ({
  authorizeAndCapturePayment: async (data) => {
    try {
      const res = await axiosInstance.post(
        "/nmi/authorizeAndCapturePayment",
        data
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  authorizePayment: async (data) => {
    try {
      const res = await axiosInstance.post("/nmi/authorizePayment", data);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  capturePayment: async (data) => {
    try {
      const res = await axiosInstance.post("/nmi/capturePayment", data);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  refundPayment: async (data) => {
    try {
      const res = await axiosInstance.post("/nmi/refundPayment", data);
      console.log("refundPayment: ~ res:", res);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },
}));

export default NMIStore;

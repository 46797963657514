// src/App.jsx
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/index";
import { initializeSocket, disconnectSocket } from "pages/websockets";
import { v4 as uuidv4 } from "uuid";
import mainStore from "store/store";
import shopifyStore from "store/shopify";
import mixpanel from "mixpanel-browser";
import GlobalTimerStore from "store/globalTimer";
mixpanel.init("09646aeb45aaa98149ac86a817d5979e");

const App = () => {
  const { getAllProducts } = shopifyStore();
  const { startCountdown } = GlobalTimerStore();
  const {
    setProductOffers,
    requiredProducts,
    getMeta,
    setUniqueUserId,
    count,
    stopCountdown,
    isRunningTimer,
    decrementTime,
  } = mainStore();

  useEffect(() => {
    checkReferer();
    initializeWebSocket();
    fetchMeta();
    fetchAllProducts();
  }, []);

  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  useEffect(() => {
    let interval;
    if (isRunningTimer) {
      interval = setInterval(() => {
        decrementTime();
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunningTimer, decrementTime]);

  useEffect(() => {
    if (count === 0) {
      stopCountdown();
      // disconnectSocket();
    }
  }, [count]);

  const checkReferer = () => {
    const referrer = document.referrer;
    console.log("checkReferer ~ referrer:", referrer);
    if (referrer && referrer === "https://www.readsleepdigest.com/") {
      console.log("Came from:", referrer);
    } else {
      console.log("No referrer found.");
    }
  };

  const initializeWebSocket = async () => {
    const uuid = uuidv4();
    const connection = await initializeSocket(uuid);
    if (connection) {
      setUniqueUserId(uuid);
    }
  };

  const fetchMeta = async () => {
    await getMeta();
  };

  const fetchAllProducts = async () => {
    const res = await getAllProducts();
    if (res) {
      const data = {
        offers: [],
        products: [],
        upsel4aKingSizes: [],
      };
      res.products.forEach((product, index) => {
        if (product.handle === "nuzzle-pillow-upgraded-to-king") {
          data.upsel4aKingSizes = setUpsel4aKingSizes(product);
        } else {
          const variant = product.variants.find((variant) => {
            return requiredProducts.includes(variant.sku);
          });

          if (variant) {
            let quantity = 0;
            let title = "";
            let img = "";
            let type = "";
            let offerType = "";

            if (variant.sku === "NZ1001" || variant.sku === "NZ1004") {
              title = "Buy 1, Save 50% OFF!";
              offerType = variant.sku === "NZ1001" ? "Standard" : "King";
              quantity = 1;
              img = "/img/pillow_price1.png";
            } else if (variant.sku === "NZ1002" || variant.sku === "NZ1005") {
              title = "Buy 2, Save 55% OFF!";
              offerType = variant.sku === "NZ1002" ? "Standard" : "King";
              quantity = 2;
              img = "/img/pillow_price2.png";
            } else if (variant.sku === "NZ1003" || variant.sku === "NZ1006") {
              title = "Buy 3, Get 1 FREE!";
              offerType = variant.sku === "NZ1003" ? "Standard" : "King";
              quantity = 4;
              img = "/img/pillow_price4.png";
            } else if (variant.sku === "NZ2001" || variant.sku === "NZ2004") {
              title = "Nuzzle Pillowcase";
              img = "/img/pillow_upsell2x.png";
              quantity = 2;
            } else if (variant.sku === "NZ2002" || variant.sku === "NZ2005") {
              title = "Nuzzle Pillowcase";
              img = "/img/pillow_upsell4x.png";
              quantity = 4;
            } else if (variant.sku === "NZ2003" || variant.sku === "NZ2006") {
              title = "Nuzzle Pillowcase";
              img = "/img/pillow_upsell6x.png";
              quantity = 6;
            } else if (variant.sku === "NZ5001") {
              title = `Nuzzle Pillow - Upgraded to King x1`;
              img = "/img/pillow_upsell6x.png";
              quantity = 6;
            } else if (variant.sku === "NZ5002") {
              title = `Nuzzle Pillow - Upgraded to King x2`;
              img = "/img/pillow_upsell6x.png";
              quantity = 6;
            } else if (variant.sku === "NZ5003") {
              title = `Nuzzle Pillow - Upgraded to King x3`;
              img = "/img/pillow_upsell6x.png";
              quantity = 6;
            } else if (variant.sku === "NZ5004") {
              title = `Nuzzle Pillow - Upgraded to King x4`;
              img = "/img/pillow_upsell6x.png";
              quantity = 6;
            } else if (variant.sku === "NZ5005") {
              title = `Nuzzle Pillow - Upgraded to King x5`;
              img = "/img/pillow_upsell6x.png";
              quantity = 6;
            } else if (variant.sku === "NZ5006") {
              title = `Nuzzle Pillow - Upgraded to King x6`;
              img = "/img/pillow_upsell6x.png";
              quantity = 6;
            } else {
              title = variant.title;
            }

            if (
              ["NZ3001", "NZ3002", "NZ4001", "NZ4002"].includes(variant.sku)
            ) {
              type = "pillow";
            }

            const obj = {
              sku: variant.sku,
              productId: variant.product_id,
              quantity: quantity,
              title: variant.title,
              label: title,
              retailValue: Number(variant.compare_at_price),
              afterDiscountValue: Number(variant.price),
              shippingCost: 0,
              img: img,
              offerType: offerType,
              shippingCost:
                variant.sku === "NZ1001" || variant.sku === "NZ1004" ? 8.95 : 0,
            };

            if (
              variant.sku === "NZ1001" ||
              variant.sku === "NZ1002" ||
              variant.sku === "NZ1003" ||
              variant.sku === "NZ1004" ||
              variant.sku === "NZ1005" ||
              variant.sku === "NZ1006"
            ) {
              data.offers.push(obj);
            } else {
              data.products.push(obj);
            }
          }
        }
      });

      console.log("fetchRequiredProducts:", data);
      data.offers.sort((a, b) => b.quantity - a.quantity);

      setProductOffers(data);
    }
  };

  const setUpsel4aKingSizes = (product) => {
    const data = [];
    product.variants.forEach((variant) => {
      data.push({
        sku: variant.sku,
        productId: product.id,
        quantity: Number(variant.title),
        title: `${product.title} x${variant.title}`,
        label: `${product.title} x${variant.title}`,
        retailValue: Number(
          variant.compare_at_price ? variant.compare_at_price : 0
        ),
        afterDiscountValue: Number(variant.price),
        shippingCost: 0,
        img: "/images/bb4.png",
        shippingCost: 0,
      });
    });

    return data;
  };

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;

import shopifyStore from "store/shopify";
import mainStore from "store/store";
import stripeStore from "store/stripe";
import paypalStore from "store/paypal";
import { trackPurchaseEvent } from "mixpanel/events";
import NMIStore from "store/nmi";

function FinalOrder() {
  const { saveCustomerOrderDetails, uniqueUserId, setOrderPlaced } =
    mainStore();
  const { createShopifyOrder } = shopifyStore();
  const { confirmPayment } = stripeStore();
  const { capturePaypalOrder } = paypalStore();
  const { authorizeAndCapturePayment } = NMIStore();

  let paymentIntent = null;

  const confirmOrder = async (intent) => {
    if (!intent) return;
    paymentIntent = intent;

    let confirm = null;
    if (paymentIntent.gateway === "stripe") {
      confirm = await confirmPayment({
        paymentIntentId: paymentIntent.paymentId,
        paymentMethod: paymentIntent.paymentMethodId,
      });
    } else if (
      paymentIntent.gateway === "paypal" ||
      paymentIntent.gateway === "card"
    ) {
      confirm = await capturePaypalOrder({
        orderID: paymentIntent.paymentId,
      });
      if (confirm && confirm.status == "COMPLETED" && confirm.purchase_units) {
        const transactionDetails =
          confirm.purchase_units[0].payments.captures[0];
        paymentIntent.paymentId = transactionDetails.id;
      } else {
        confirm = null;
      }
    } else if (paymentIntent.gateway === "nmi") {
      if (!paymentIntent.firstOrder) {
        const offerAmount = paymentIntent.details?.items[0]?.price;
        if (offerAmount) {
          const updatedAmount =
            Math.round((paymentIntent.amount - offerAmount) * 100) / 100;
          confirm = await authorizeAndCapturePayment({
            ...paymentIntent.nmiDetails,
            amount: updatedAmount,
          });
          paymentIntent.paymentId = confirm.transactionid;
          if (confirm.response_code != 100) {
            confirm = null;
          }
        }
      } else {
        confirm = {
          response_code: 100,
        };
      }
    }

    if (confirm) {
      if (paymentIntent.gateway !== "nmi" || confirm.response_code == 100) {
        await saveFinalOrder(paymentIntent);
      } else if (paymentIntent.gateway === "nmi") {
        alert(confirm.responsetext);
      }
    } else {
      alert("Cannot place order. Try again");
    }
  };

  const saveFinalOrder = async (intent) => {
    setOrderPlaced(true);
    if (!intent) return;
    paymentIntent = intent;
    const shopifyData = returnAllShopifyInfo();
    const shopifyDetails = await createShopifyOrder(shopifyData);
    console.log("saveFinalOrder ~ shopifyDetails:", shopifyDetails);
    if (shopifyDetails) {
      trackPurchaseEvent(paymentIntent.details);
      const allOrderData = returnAllOrderInfo();
      allOrderData.shopifyOrderName = shopifyDetails.order.name;
      allOrderData.shopifyOrderId = shopifyDetails.order.id;
      allOrderData.paymentId = paymentIntent.paymentId;
      await saveCustomerOrderDetails(allOrderData);
    }
  };

  const returnAllShopifyInfo = () => {
    const customer = paymentIntent.details.customer;
    const details = paymentIntent.details;

    if (paymentIntent.gateway === "paypal" && details.items[0]) {
      details.items[0].price = details.offer.afterDiscountValue;
    }

    const totalAmount = details.items.reduce((acc, item) => {
      return acc + item.price;
    }, 0);

    return {
      orderData: {
        distinct_id: uniqueUserId,
        financial_status: "paid",
        fulfillment_status: "unfulfilled",
        line_items: details.items,
        shipping_lines: [
          {
            title: "Standard Shipping",
            price: details.offer.shippingCost,
            code: "standard",
          },
        ],
        customer: {
          first_name: customer.firstName,
          last_name: customer.lastName,
          email: customer.email,
          default_address: {
            address1: customer.line1,
            address2: customer.line2,
            city: customer.city,
            province: customer.state,
            country: customer.country,
            zip: customer.postal_code,
          },
        },
        shipping_address: {
          first_name: customer.firstName,
          last_name: customer.lastName,
          address1: `${customer.line1}, ${customer.line2}`,
          phone: customer.phone,
          city: customer.city,
          province: customer.state,
          country: customer.country,
          zip: customer.postal_code,
        },
        billing_address: {
          first_name: customer.billingAddress?.first_name,
          last_name: customer.billingAddress?.last_name,
          address1: `${customer.billingAddress?.address1}, ${customer.billingAddress?.address2}`,
          phone: customer.phone,
          city: customer.billingAddress?.city,
          province: customer.billingAddress?.state,
          country: customer.billingAddress?.country,
          zip: customer.billingAddress?.zip,
        },
        transactions: [
          {
            kind: "sale",
            status: "success",
            amount: totalAmount + details.offer.shippingCost,
            currency: details.currency,
            gateway: paymentIntent.gateway,
          },
        ],
      },
    };
  };

  const returnAllOrderInfo = (data) => {
    const customer = paymentIntent.details.customer;
    const details = paymentIntent.details;

    const totalAmount = paymentIntent.details.items.reduce((acc, item) => {
      return acc + item.price;
    }, 0);

    return {
      customerDetails: {
        name: `${customer.firstName} ${customer.lastName}`,
        address: `${customer.line1}, ${customer.line2}`,
        country: customer.country,
        state: customer.state,
        city: customer.city,
        zipcode: customer.postal_code,
        phone: customer.phone,
        email: customer.email,
      },
      offerDetails: {
        upgradedToKing: details.upgradedToKing,
      },
      paymentDetails: {
        method: paymentIntent.gateway,
        amount: Math.round(totalAmount * 100) / 100,
        currency: details.currency,
        paymentId: paymentIntent.paymentId,
        status: details.status,
      },
    };
  };

  return { saveFinalOrder, confirmOrder };
}

export default FinalOrder;

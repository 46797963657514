import mixpanel from "mixpanel-browser";
import mainStore from "store/store";

const userId = () => {
  const { uniqueUserId } = mainStore();
  return uniqueUserId;
};

export const trackPageViewEvent = (data) => {
  mixpanel.track("PageView", {
    distinct_id: userId,
    time: Math.floor(Date.now() / 1000),
    is_landing: true,
    page_url: window.location.href,
    page_type: data.page_type,
  });
};

export const trackAddToCartEvent = () => {
  mixpanel.track("Add to Cart", {
    distinct_id: userId,
    time: Math.floor(Date.now() / 1000),
    page_url: window.location.href,
  });
};

export const trackCheckoutStartedEvent = () => {
  mixpanel.track("Checkout Started", {
    distinct_id: userId,
    time: Math.floor(Date.now() / 1000),
    page_url: window.location.href,
  });
};

export const trackUpsellAccepted = (sku) => {
  mixpanel.track("Upsell Accepted", {
    distinct_id: userId,
    time: Math.floor(Date.now() / 1000),
    page_url: window.location.href,
    sku: sku,
  });
};

export const trackPurchaseEvent = (details) => {
  const totalAmount = details.items.reduce((acc, item) => {
    return acc + item.price;
  }, 0);

  const customer = details.customer;
  mixpanel.track("Purchase", {
    distinct_id: userId,
    time: Math.floor(Date.now() / 1000),
    user_email: customer.email,
    total_amount: totalAmount || 0,
    payment_method: details?.gateway || "credit_card",
    currency: "USD",
    billing_country: "US",
    shipping_country: "US",
    cart_items: details.items,
    shipping_method: "standard",
  });
};

import React from "react";

function Footer() {
  return (
    <section class="footer-new-sec">
      <img
        src="../images/footer-logo.png"
        loading="lazy"
        alt=""
        class="footer-logo-img formobile"
      />
      <div class="div-block-553">
        <div class="div-block-509">
          <div class="footer-link-text">
            <a
              href="http://offer.getnuzzle.com/about-us"
              target="_blank"
              class="link-20"
            >
              About Us |
            </a>

            <a
              href="http://offer.getnuzzle.com/legal/terms-conditions"
              target="_blank"
              class="link-19"
            >
              Terms &amp; Conditions |
            </a>

            <a
              href="https://offer.getnuzzle.com/legal/privacy-policy"
              target="_blank"
              class="link-18"
            >
              Privacy Policy |
            </a>

            <a
              href="https://offer.getnuzzle.com/legal/return-policy"
              target="_blank"
              class="link-14"
            >
              Returns |
            </a>

            <a
              href="https://offer.getnuzzle.com/legal/contact-us"
              target="_blank"
              class="link-15"
            >
              Contact Us
            </a>
            <a href="#" target="_blank" class="link-one"></a>
          </div>
        </div>
        <img
          src="images/footer-logo.png"
          loading="lazy"
          alt=""
          class="footer-logo-img"
        />
        <div class="copy-right-text">Copyright 2024. All rights reserved.</div>
        <div class="copy-right-text text-center">
          Dynamic Home Group Inc. 7886 Deering Ave, Canoga Park, CA 91304
        </div>
      </div>
    </section>
  );
}

export default Footer;

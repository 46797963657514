import { create } from "zustand";

const GlobalTimerStore = create((set) => ({
  hours: "00",
  minutes: "00",
  seconds: "00",
  startCountdown: () => {
    const updateCountdown = () => {
      const now = new Date();
      const midnight = new Date(now);
      midnight.setHours(24, 0, 0, 0); // Set to midnight

      const remainingTime = midnight - now; // Milliseconds until midnight
      if (remainingTime <= 0) {
        set({ hours: "00", minutes: "00", seconds: "00" });
        return; // Stop if it's already past midnight
      }

      const hours = String(
        Math.floor((remainingTime / (1000 * 60 * 60)) % 24)
      ).padStart(2, "0");
      const minutes = String(
        Math.floor((remainingTime / (1000 * 60)) % 60)
      ).padStart(2, "0");
      const seconds = String(Math.floor((remainingTime / 1000) % 60)).padStart(
        2,
        "0"
      );

      set({ hours, minutes, seconds });
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    // Clear interval on unmount
    return () => clearInterval(intervalId);
  },
}));

export default GlobalTimerStore;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import mainStore from "store/store";
import stripeStore from "store/stripe";
import paypalStore from "store/paypal";
import FinalOrder from "pages/finalOrder/finalOrder";
import { sendMessageToUser } from "pages/websockets";
import Mixin from "pages/mixin/global";
import { trackUpsellAccepted, trackPageViewEvent } from "mixpanel/events";

function UpSell4A() {
  const navigate = useNavigate();
  const { placeOrder } = Mixin();
  const { allProductOffers, paymentIntent, setPaymentIntent, formattedTime } =
    mainStore();
  const { updatePaymentIntent, confirmPayment } = stripeStore();
  const { updatePaypalOrder, capturePaypalOrder } = paypalStore();
  const { saveFinalOrder } = FinalOrder();

  const [loading, setLoading] = useState(false);
  const [pillowQuantity, setPillowQuantity] = useState(false);
  const [variant, setVariant] = useState(null);

  const price = 9.99;

  useEffect(() => {
    trackPageViewEvent({ page_type: "Upsell 4A" });
    if (!paymentIntent) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (paymentIntent) {
      setUpsellDeal();
    }
  }, [paymentIntent]);

  const setUpsellDeal = () => {
    let quantity = 0;
    paymentIntent.details.items.forEach((item) => {
      if (item.sku === "NZ1003") {
        quantity += 4;
      } else if (item.sku === "NZ1002") {
        quantity += 2;
      } else if (item.sku === "NZ1001") {
        quantity += 1;
      } else if (item.sku === "NZ3001") {
        quantity += 2;
      } else if (item.sku === "NZ4001") {
        quantity += 1;
      }
    });
    setPillowQuantity(quantity);
    const variant = allProductOffers.upsel4aKingSizes.find((size) => {
      return size.quantity === quantity;
    });
    console.log("variant ~ variant:", variant);
    setVariant(variant);
  };

  const onAddPillow = async () => {
    try {
      let res = null;
      let amount = 0;
      setLoading(true);

      if (paymentIntent.gateway === "stripe") {
        amount = paymentIntent.amount + 100 * (price * pillowQuantity);
        res = await updatePaymentIntent({
          paymentIntentId: paymentIntent.paymentId,
          amount: amount,
        });
      } else if (
        paymentIntent.gateway === "paypal" ||
        paymentIntent.gateway === "card"
      ) {
        amount =
          Math.round((paymentIntent.amount + price * pillowQuantity) * 100) /
          100;
        res = await updatePaypalOrder({
          orderID: paymentIntent.paymentId,
          amount: amount,
        });
      } else if (paymentIntent.gateway === "nmi") {
        amount =
          Math.round((paymentIntent.amount + price * pillowQuantity) * 100) /
          100;
        res = {};
      }

      if (res) {
        const newPaymentIntent = { ...paymentIntent };
        newPaymentIntent.amount = amount;
        let updatedItems = upsellAccepted();
        updatedItems.push({
          title: variant.title,
          price: price * pillowQuantity,
          quantity: 1,
          sku: variant.sku,
          product_id: variant.productId,
        });
        newPaymentIntent.details.items = updatedItems;

        let confirm = null;
        if (paymentIntent.gateway === "stripe") {
          confirm = await confirmPayment({
            paymentIntentId: paymentIntent.paymentId,
            paymentMethod: paymentIntent.paymentMethodId,
          });
        } else if (
          paymentIntent.gateway === "paypal" ||
          paymentIntent.gateway === "card"
        ) {
          confirm = await capturePaypalOrder({
            orderID: paymentIntent.paymentId,
          });
          const transactionDetails =
            confirm.purchase_units[0].payments.captures[0];
          newPaymentIntent.paymentId = transactionDetails.id;
        }
        if (confirm) {
          trackUpsellAccepted(variant.sku);
          setPaymentIntent(newPaymentIntent);
          await saveFinalOrder(newPaymentIntent);
          sendMessageToUser({ orderComplete: true });
          navigate("/thank-you");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("onAddPillow ~ error:", error);
      setLoading(false);
      alert("Cannot create order at the moment.");
    }
  };

  const upsellAccepted = () => {
    const newPaymentIntent = { ...paymentIntent };
    console.log("upsellAccepted ~ newPaymentIntent:", newPaymentIntent);
    const offer = newPaymentIntent.details?.items[0] || null;
    if (offer && offer.sku) {
      delete offer.sku;
    }
    let items = [];
    items.push(offer);

    const casses = newPaymentIntent.details.items.find((offer) => {
      return (
        offer.sku === "NZ2001" ||
        offer.sku === "NZ2002" ||
        offer.sku === "NZ2003"
      );
    });

    let kingCase = null;
    if (casses) {
      if (casses.sku === "NZ2001") {
        kingCase = allProductOffers.products.find((offer) => {
          return offer.sku === "NZ2004";
        });

        if (kingCase) {
          items.push({
            title: kingCase.title,
            price: kingCase.afterDiscountValue,
            quantity: 1,
            sku: kingCase.sku,
            product_id: kingCase.productId,
          });
        }
      } else if (casses.sku === "NZ2002") {
        kingCase = allProductOffers.products.find((offer) => {
          return offer.sku === "NZ2005";
        });

        if (kingCase) {
          items.push({
            title: kingCase.title,
            price: kingCase.afterDiscountValue,
            quantity: 1,
            sku: kingCase.sku,
            product_id: kingCase.productId,
          });
        }
      } else if (casses.sku === "NZ2003") {
        kingCase = allProductOffers.products.find((offer) => {
          return offer.sku === "NZ2006";
        });

        if (kingCase) {
          items.push({
            title: kingCase.title,
            price: kingCase.afterDiscountValue,
            quantity: 1,
            sku: kingCase.sku,
            product_id: kingCase.productId,
          });
        }
      }
    }

    const bogo = newPaymentIntent.details?.items.find((offer) => {
      return offer.sku === "NZ3001";
    });

    if (bogo) {
      items.push({
        title: bogo.title,
        price: bogo.price,
        quantity: 1,
        product_id: bogo.productId,
      });
    }

    const special = newPaymentIntent.details?.items.find((offer) => {
      return offer.sku === "NZ4001";
    });

    if (special) {
      items.push({
        title: special.title,
        price: special.price,
        quantity: 1,
        product_id: special.productId,
      });
    }

    console.log("upsellAccepted ~ items:", items);
    return items;
  };

  const navigateToNextUpsell = async () => {
    await placeOrder(paymentIntent);
    setLoading(false);
  };

  return (
    <>
      <div class="progressbar-section">
        <div>
          <img
            src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f222c4dc2ef4647b6c690_progress%20bar.svg"
            loading="lazy"
            alt=""
            class="image-18"
          />
        </div>
      </div>
      <div class="logo-section">
        <div class="container-3 container-upsell w-container">
          <div class="upsell-header">
            <div class="logo-block upsell-logo-block">
              <img
                src="../images/logo_v02.png"
                loading="lazy"
                width="113"
                alt=""
              />
            </div>
            <div class="progress-container">
              <div class="progress-step">
                <a href="#" class="progress-link w-inline-block">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2d756903139a8964573_one.png"
                    loading="lazy"
                    alt=""
                    class="image-21 link-img"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f27534ea18b1c3eedbe8a_st1.svg"
                    loading="lazy"
                    alt=""
                    class="pr-r r-pr-r"
                  />
                </a>
                <p class="progress-p">Checkout</p>
              </div>
              <div class="progress-step">
                <a href="#" class="progress-link w-inline-block">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2ebbce1fa36907e580e_two.png"
                    loading="lazy"
                    alt=""
                    class="image-21 link-img"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f277596949e5bacc01176_st2.svg"
                    loading="lazy"
                    width="414"
                    alt=""
                    class="pr-r c-pr-r"
                  />
                </a>
                <p class="progress-p">Special Deal</p>
              </div>
              <div class="progress-step ls">
                <div class="progress-link">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2f9037c341fc9b203c4_three.png"
                    loading="lazy"
                    alt=""
                    class="image-21 link-img"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f279000ea000b17acdf08_st3.svg"
                    loading="lazy"
                    alt=""
                    class="pr-r l-pr-r"
                  />
                </div>
                <p class="progress-p">Summary</p>
              </div>
              <img
                src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa9a0a2c9d72c22d4eb3c_rl.svg"
                loading="lazy"
                alt=""
                class="image-22"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="main-section main-section-upsell">
        <div class="container-3 container-upsell main-container-upsell w-container">
          <div class="upsell-main-top-block">
            <h5 class="sale-title">
              YOU’RE IN LUCK! YOU QUALIFY FOR OUR
              <span class="text-span-92">DISCOUNTED KING UPGRADE!</span>
            </h5>
          </div>
          <div class="timer-div">
            <div class="note-text">
              But hurry, this limited time offer expires in{" "}
              <span id="timer" class="ff-ti">
                {formattedTime}
              </span>{" "}
              minutes, so act fast.
            </div>
          </div>
          <div class="center-div">
            <p class="sale-p">
              Our customers can&#x27;t resist the king size pillow, and we
              don&#x27;t blame them. With an extra nine inches of length, the
              added surface area lessens tossing and turning, and provides great
              back support while reading or watching TV in bed.
            </p>
          </div>
          <div class="columns upsell-cl w-row">
            <div class="column-2 upsell-slider-coloumn w-col w-col-6">
              <div class="upsell-slider-wrapper">
                <div class="main-dots upsell-dots">
                  <div class="slide-dot">
                    <img
                      src="../images/bb4.png"
                      loading="lazy"
                      sizes="(max-width: 479px) 96vw, (max-width: 767px) 100vw, (max-width: 991px) 45vw, 445px"
                      srcset="
                      ../images/bb4-p-500.png 500w,
                      ../images/bb4.png       754w
                    "
                      alt=""
                      class="slide-dot-iimg"
                    />
                  </div>
                  <div class="slide-dot"></div>
                  <div class="slide-dot"></div>
                  <div class="slide-dot"></div>
                  <div class="slide-dot"></div>
                </div>
                <img
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f176839d9035414ca219f_prev.svg"
                  loading="lazy"
                  alt=""
                  class="prev-arr slick-arrow upsell-arr"
                />
                <img
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f17805bff702023229745_next.svg"
                  loading="lazy"
                  alt=""
                  class="next-arr slick-arrow upsell-arr"
                />
                <img
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/6270e07371b4bf03848279bc_best.png"
                  loading="lazy"
                  alt=""
                  class="best-slider-icon"
                />
              </div>
            </div>
            <div class="column-4 w-col w-col-6">
              <div id="redeem" class="main-text-block upsell-main-text-block">
                <h1 class="main-title _2ndname">Nuzzle Pillow</h1>
                <div class="stars-block">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/623750a74497753d5f3105f3_rating%20stars.svg"
                    loading="lazy"
                    alt=""
                  />
                  <p class="stars-text upsell-stars-text">410 Reviews</p>
                </div>
                <div class="gift-row upsell-gift-row">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e96079fa1145743416b70_free.svg"
                    loading="lazy"
                    alt=""
                    class="image-13"
                  />
                  <div class="gift-text-block">
                    <p class="paragraph-15 upsell-p15">
                      <strong class="bold-text-3">
                        This offer is available only with today’s purchase!
                      </strong>
                    </p>
                  </div>
                </div>
                <p class="paragraph-11 upsell-p11">
                  <br />
                  Almost all of our customers eventually upgrade to the
                  king-size pillow and now you can too, for only $9.99 each -
                  that’s 50% OFF! Don&#x27;t miss out on this amazing deal and
                  upgrade to the ultimate sleep experience.
                </p>
                <div class="main-list-block">
                  <div class="main-list-row">
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e9dd6b16093516ed79df4_list%20icon.svg"
                      loading="lazy"
                      alt=""
                    />
                    <p class="list-p">
                      Greater comfort leading to better alignment and less
                      strain on muscles 
                    </p>
                  </div>
                  <div class="main-list-row">
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e9dd6b16093516ed79df4_list%20icon.svg"
                      loading="lazy"
                      alt=""
                    />
                    <p class="list-p">
                      More room for movement to find that perfect position 
                    </p>
                  </div>
                  <div class="main-list-row">
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e9dd6b16093516ed79df4_list%20icon.svg"
                      loading="lazy"
                      alt=""
                    />
                    <p class="list-p">
                      Perfect those that are taller or have wide shoulders
                    </p>
                  </div>
                  <div class="main-list-row">
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e9dd6b16093516ed79df4_list%20icon.svg"
                      loading="lazy"
                      alt=""
                    />
                    <p class="list-p">
                       Looks grander and gives a luxurious look to your bedding 
                    </p>
                  </div>
                </div>
                <div class="pp_div">
                  <div class="div_ss">
                    <div>
                      <h1 class="heading-24">
                        ${price}
                        <span class="text-span-77">/ea</span>
                      </h1>
                      <div class="text-block-112">Orig 19.99/ea</div>
                    </div>
                    <div class="div-block-450"></div>
                  </div>
                </div>
                <div
                  data-upsell-type="add-variant"
                  class="accept_upsell_button checkout-accept-upsell w-inline-block"
                >
                  <button
                    className={`button-gold checkout-accept-upsell4-forupgrade ${
                      loading ? "order-processing" : ""
                    }`}
                    style={{
                      backgroundColor: loading ? "#294a59" : "#29af5c",
                    }}
                    disabled={loading}
                    onClick={() => onAddPillow()}
                  >
                    <div class="button-txt-icon-2">
                      <div class="add-to-order-2">
                        {loading ? (
                          <span className="pay-now">
                            <i> Processing Order</i>{" "}
                            <PulseLoader color="#ffffff" size={8} />
                          </span>
                        ) : (
                          <strong>YES! UPGRADE MY ORDER!</strong>
                        )}
                      </div>
                    </div>
                  </button>
                </div>
                <div class="form-information upsell-form-inf">
                  <div class="g-text">
                    TRY IT RISK FREE! 90-DAY MONEY BACK GUARANTEE
                  </div>
                </div>
                <a
                  class="no-offer-block w-inline-block cursor-pointer"
                  onClick={() => {
                    navigateToNextUpsell();
                  }}
                >
                  <p class="no-offer-text">
                    No, thank you! I don&#x27;t want this one time offer.
                  </p>
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625ef1f759479c8b18be44f8_Path.svg"
                    loading="lazy"
                    alt=""
                    class="image-17"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-6">
        <div class="inner-upsell">
          <div class="text-block-24">
            <a
              href="../legal/privacy-policy.html"
              target="_blank"
              class="link-5"
            >
              Privacy Policy
            </a>
            | 
            <a
              href="../legal/terms-conditions.html"
              target="_blank"
              class="link-5"
            >
              Terms &amp; Conditions
            </a>
            |
            <a
              href="../legal/return-policy.html"
              target="_blank"
              class="link-8"
            >
              Refund Policy
            </a>
            |
            <a href="../legal/contact-us.html" target="_blank" class="link-9">
              Contact
            </a>
            | 
            <a href="../about-us.html" target="_blank" class="link-10">
              About
            </a>
            <br />© 2023 Nuzzle. All Rights Reserved.
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default UpSell4A;
